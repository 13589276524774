::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: linear-gradient(0deg, rgba(40,40,40,1) 0%, #2A9D8F 32%, #264653 68%, rgba(40,40,40,1) 100%);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 2px;
  background: #ffffff;
}

.App-header {
  background-color: #282c34;
  color: white;
}
html, .body{
  font-family: Courier New;
  font-weight: 300;
  color: #202020;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-size: wrap;
}


.body{
  overflow-y: hidden;
  font-size: 20px;
  padding: 0;
  margin: 0;
}
/* hero */
.hero{
  min-height: 100vh;
  background-color: rgb(40, 40, 40);
}

.grid-container {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr 1.6fr 0.4fr;
  grid-template-rows: 0.6fr 1.4fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "pic . . ." "About About About ." "About About About .";
}
.pic { grid-area: pic; }
.About { 
  grid-area: About; 
  padding-left: 20px;
}


.pic{
  margin-left: 20px;
  margin-top: 20px;
}

.About
{
  color: white;
}

.pic img{
  height: 170px;
  width: 170px;
  text-align: center;
}



.heading{
  color: #ffebeb;
  margin: 0 0 0 0;
}

.About h3{

  font-size: 2.5rem;
  padding-left: 5%;
  width: 50%;
  /* float: inline-start; */
  transition: all .5s ease-in-out;
}

.About h3:hover {
  color: #b5b3b3;
  transform: scale(1.1);
}
.About h5{
  padding-right: 50px;
  padding-left: 20px;
  word-spacing: 3px;
  text-align: justify;
  line-height: 2em;
  font-family: Courier New;
}
.About h5::before{
  content: '<san><san>';
  background-color: white;
}
.About h5::after{
  content: '<san><san>';
  background-color: white;
}



/* second */
.What{
  min-height: 100vh;
  align-items: center;
  background-color: #2A9D8F;
}

.grid-container-2 {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr 1.6fr 0.4fr;
  grid-template-rows: 0fr 2.7fr 0.3fr;
  gap: 1px 1px;
  grid-template-areas: ". . . ." "what what what ." ". . . .";
}

.what { 
  grid-area: what;
  padding-right: 50px;
  padding-left: 50px;
}
.what h3{
  /* padding-left: 5%; */
  padding-bottom: 5%;
  width: max-content;
  margin-top: 100px;
  font-size: 2rem;
  /* text-decoration: underline;
  text-underline-offset: 10px; */
}


.offset {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0px;
  font-family: Courier New;
  min-height: 100px;
  color: #ffffff;
  font-size: 20px;
  /* max-width: 40%; */
  max-width: max-content;
  margin-top: 10px;
  margin-right: 5px;
  background-color: #2A9D8F;
  padding: 10px 20px 10px 20px;
  border: solid white 7px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.offset:active,.offset:hover {
  background: #3ec2a1;
  text-decoration: none;
  transform: scale(0.9);
}



/* E76F51
F4A261
E9C46A
2A9D8F
264653 */
/* third */
.Skills{
  min-height: 100vh;
  background-color: #264653;
}
.grid-container-3 {
  display: inline-grid;
  grid-template-columns: 0.4fr 1.6fr 1.5fr 0.5fr;
  grid-template-rows: 0fr 2.7fr 0.3fr;
  gap: 1px 1px;
  grid-template-areas: ". . . ." ". skill skill ." ". . . .";
  padding-right: 10px;
}
.skill { 
  grid-area: skill;
}
.skill h3{
  padding-bottom: 2.5%;
  margin-top: 100px;
  font-size: 2rem;
}
/* .skill h3:hover::after{
  color: #225555;
  background-color: #225555;
  content: '<>';
}
.skill h3::after{
  font-size: 4px;
  color: whitesmoke;
  background-color:whitesmoke;
  content: '<>';
  vertical-align: text-top;
} */

.grid-container-skill {
  display: inline-block ;
  padding-right: 10px;
  grid-template-columns: 1.5fr 2.5fr 0fr;
  grid-template-rows: 0.3fr 1.8fr 0.9fr;
  gap: 1px 1px;
  grid-template-areas: "tabname items ." "tabname items ." "tabname items .";
  margin-bottom: 10px;
  max-width: 400px;

  font-family: Courier New;
}

.tabname{
  grid-area:tabname ;
  margin-bottom: 4px;
  padding: 8px;
  width: 130px;
  font-size: 15px;
  color: #264653;
  margin-right: 10px;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px 0 #00000033;
}
.items{
  color: white;
  max-width: 200px;
  border: 2px solid white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  grid-area: items;
}
.items ul{
  padding-right: 10px;
  padding-left: 30px;
}

/* fourth */

.Projects{
  background-color: rgb(40, 40, 40);
}


.grid-container-4 {
  display: grid;
  grid-template-columns: 0.4fr 1.3fr 1.6fr 0.4fr;
  grid-template-rows: 0fr 2.8fr 0.2fr;
  gap: 1px 1px;
  grid-template-areas: ". . . ." ". project project ." ". . . .";

  align-items: baseline;
}
.project{
  grid-area: project; 
}
.project h3{
  padding-bottom: 2.5%;
  margin-top: 100px;
  font-size: 2rem;
}

/* cards */
.card{
  width: max-content;
  max-width: 200px;
  float: left;
  color: #53ffd4;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  /* display: inline-block; */
  position: relative;
  padding: 0.5em 1em;
  text-decoration: none;
  transition: .4s;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}
/* card sett */

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.card h3{
  margin-top: 10px;
  font-size: 1.5rem;
}

.card a{
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
}

/* fifth */

.Designs{
  background-color: #225555;
}
.grid-container-5 {
  display: grid;
  grid-template-columns: 0.4fr 1.6fr 1.6fr 0.4fr;
  grid-template-rows: 0fr 2.9fr 0.1fr;
  gap: 1px 1px;
  grid-template-areas: ". . . ." ". designs designs ." ". . . .";
}
.design { 
  grid-area: designs;
  display: inline-block;
}
.design h3{
  /* padding-bottom: 2.5%; */
  margin-top: 80px;
  font-size: 2rem;
}

.designitems .disp{
  margin: 10px;
  width: 80vw;
  height: auto;
}
/* fifth over */
/*sidebar*/

.links {
  padding-top: 40px;
  position: fixed;
  right:  1.2vw;
  width: 80px;
  margin-left: 10px;

}

.l {
  position: relative;
  height: 100px;
  width: 100px;
  font-size: 50px;
  text-align: center;
  color: white;
  transition: all 0.25s ease-in-out;
}


.l:hover,l:active{
  transform: translateX(-10px); 
}


/* over */


@media only screen and (min-width: 700px){
  .hero{
    height: 100vh;
  }
  .What{
    height: 100vh;
  }
  .grid-container-3 {
    grid-template-columns: 0.2fr 1.6fr 1.6fr 0.4fr;
    grid-template-areas: ". . . ." "skill skill skill ." ". . . .";
  } 
  .Skills{
    height: 100vh;
  }
  .skill{
    padding-left: 50px;
  }
  .grid-container-skill {
    display: inline-grid;
    align-items: flex-start;
    align-self: initial;
  } 
  .tabname{
    font-size: 20px;
  }
  .grid-container-4 {
  grid-template-columns: 0.2fr 1.3fr 1.6fr 0.4fr;
  grid-template-areas: ". . . ." "project project project ." ". . . .";
  }
  .Projects{
    height: 100vh;
  }
  .project{
  padding-left: 50px;
  }
  .project .card{
    width: max-content;
    max-width: 300px;
  }


  .grid-container-5 {
    grid-template-columns: 0.2fr 1.6fr 1.6fr 0.4fr;
    grid-template-areas: ". . . ." ". designs designs ." ". . . .";
  } 
  .Designs{
    /* height: 100vh; */
  height: max-content;
  }
  .designs{
    padding-left: 50px;
  }
  .designitems .disp{
    margin: 10px;
    width: 480px;
    height: auto;
  }
  /* .designitems{
    font-size: 20px;
  } */
} 

.reading-progress-bar {
  z-index: 1;
  position: fixed;
  height: 5px;
  left: 0;
  background: linear-gradient(90deg, rgba(0,255,255,1) 0%, rgba(58,194,61,1) 32%, rgba(223,63,42,1) 68%, rgba(255,233,0,1) 100%);
}
.progress-bar {
  z-index: 3;
  position: fixed;
  height: 5px;
  left: 0;
 }

 /*  */
 